import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home-routes').then((m) => m.routes),
      },
      {
        path: 'web',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./web/web-routes').then((m) => m.routes),
      },
      {
        path: 'mobile',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./mobile/mobile-routes').then((m) => m.routes),
      },
      {
        path: 'desktop',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./desktop/desktop-routes').then((m) => m.routes),
      },
      {
        path: 'hardware',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./hardware/hardware-routes').then((m) => m.routes),
      },
      {
        path: 'wearables',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./wearables/wearables-routes').then((m) => m.routes),
      },
      {
        path: 'conversational-ui',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./conversational/conversational-routes').then((m) => m.routes),
      },
      {
        path: 'printed-materials',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./printed/printed-routes').then((m) => m.routes),
      },
      {
        path: 'illustrations',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./illustrations/illustrations-routes').then((m) => m.routes),
      },
      {
        path: 'design',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./design/design-routes').then((m) => m.routes),
      },
      {
        path: 'brand',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./brand/brand-routes').then((m) => m.routes),
      },
      {
        path: 'content',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./content/content-routes').then((m) => m.routes),
      },
      {
        path: 'videos',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./videos/videos-routes').then((m) => m.routes),
      },
      {
        path: 'pages',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./pages/pages-routes').then((m) => m.routes),
      },
      {
        path: 'downloads',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./downloads/downloads-routes').then((m) => m.routes),
      },
      {
        path: 'globalization',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./globalization/globalization-routes').then((m) => m.routes),
      },
      {
        path: 'accessibility',
        loadComponent: () => import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
        loadChildren: () => import('./accessibility/accessibility-routes').then((m) => m.routes),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      // {
      //   path: '**',
      //   redirectTo: 'home'
      // }
    ],
  },
  // path is outside so that it loads full screen

  {
    path: 'demo',
    loadChildren: () => import('./demo/demo-routes').then((m) => m.routes),
  },
  {
    path: 'sampleapp',
    loadChildren: () => import('./sample-app/sample-app-routes').then((m) => m.routes),
  },
];
