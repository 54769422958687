import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Globals {
  appName = 'Design System';
  sidebar = true;
  hasGlobalAlert = false;
}
